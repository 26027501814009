@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: theme("fontFamily.sans");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: theme("colors.app-purple-1");
  }
  body > .print-source {
    display: block;
    text-wrap: normal;
  }
}

@layer components {
  /* Draw grid lines for day and week view */
  .mode-day-grid {
    background-image: repeating-linear-gradient(
      to right,
      #e3cde3 0 1px,
      transparent 1px 100%
    );
    background-size: calc(100% / 24);
  }

  .mode-week-grid {
    background-image: repeating-linear-gradient(
      to right,
      #e3cde3 0 1px,
      transparent 1px 100%
    );
    background-size: calc(100% / 7);
  }

  @keyframes dash {
    to {
      background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
  }

  .dashed {
    background: linear-gradient(
        90deg,
        theme("colors.app-black") 50%,
        transparent 50%
      ),
      linear-gradient(90deg, theme("colors.app-black") 50%, transparent 50%),
      linear-gradient(0deg, theme("colors.app-black") 50%, transparent 50%),
      linear-gradient(0deg, theme("colors.app-black") 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 2px, 16px 2px, 2px 16px, 2px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 10s linear infinite;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f3;
}

::-webkit-scrollbar-thumb {
  /* background-color: #484848; */
  background-color: theme("colors.app-purple-10");
}

.inner-scroll::-webkit-scrollbar-thumb {
  background-color: theme("colors.app-purple-10");
  border: 2px solid #f0f0f3;
}

@layer components {
  .pill {
    border-radius: theme("borderRadius.lg");
    padding: 5px;
    padding-left: theme("padding.2");
    padding-right: theme("padding.2");
    margin: 5px;
    letter-spacing: theme("letterSpacing.widest");
    font-size: 14px;
    background-color: theme("colors.app-purple-3");
    color: theme("colors.white");
  }
  .panelStyle{
    @apply relative w-full max-w-xl p-6 overflow-auto max-h-screen text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl
  }
}
.react-timerange-picker > * {
  @apply font-sans;
}
.react-datepicker > * {
  @apply font-sans;
}

.react-datepicker__navigation {
  @apply mt-2;
}

.react-datepicker__header {
  @apply bg-[#F6EDF6];
}

.react-datepicker__day-names {
  @apply mt-3;
}

.react-datepicker__day--outside-month {
  opacity: 0.5;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply font-medium rounded-full bg-[#892E89];
}

.PhoneInput {
  @apply w-full pl-2 rounded bg-[#B58EB8B2];
}

.PhoneInput input {
  @apply !bg-transparent border-0;
}

.tiny-select {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71915 5L0.948242 0H8.49006L4.71915 5Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
  background-position: 1.9rem 0.2rem !important;
  background-size: 0.7em 1em !important;
}

.tiny-select:focus {
  border: none;
  box-shadow: none;
}

.rmpd-container {
  display: block;
  z-index: 500000;
  position: absolute;
}
