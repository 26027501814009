.table thead th:first-child {
  border-top-left-radius: 24px;
  overflow: hidden;
}

.table thead th:last-child {
  border-top-right-radius: 24px;
  overflow: hidden;
}

.table tr:last-child {
  border-bottom: none;
}

.table tr:last-child td:first-child {
  border-radius: 0 0 0 24px;
}

.table tr:last-child td:last-child {
  border-radius: 0 0 24px 0;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  /* min-width: 350px; */
}

.table tr th,
.table tr td {
  border-bottom: 1px solid #8177b2;
}
.table th {
  border-top: 1px solid #8177b2;
}

.table tr th:first-child,
.table tr td:first-child {
  border-left: 1px solid #8177b2;
}
.table tr th:last-child,
.table tr td:last-child {
  border-right: 1px solid #8177b2;
}

